import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import ApiService from "../../network/ApiService";

function Message() {
    const {
        fetchMessages,
    } = ApiService();

    const {organizationId} = useSelector((state) => state.organization);
    const languageStatus = sessionStorage.getItem("language");
    const [messages, setMessages] = useState([]);
    const [label, setLabel] = useState(null);
    const params = useParams();
    const messageId = params.messageId;
    const getMessage = async () => {
        try {
            const response = await fetchMessages();
            setMessages(response.data.mapOfIdAndDetails[messageId]);
            setLabel(response.data.mapOfIdAndDisplayLabels[messageId]);
        } catch (error) {
            console.log(error.message);
        }
    };

    const parseHtmlToView = (str) => {
        return {__html: str};
    };

    useEffect(() => {
        if (organizationId && messageId) {
            getMessage();
        }
    }, [organizationId, messageId]);

    return (
        <>
            <section className="ftco-section ftco-no-pb ftco-no-pt mt-2">
          <div className="container about-container position-relative">
              <div className="row no-gutters ">
                  <div className="col-md-8 wrap-about ftco-animate">
                      <div className="bg-light  px-md-4 py-5 ">
                          <div className="heading-section">
                              <h2 className="mb-3">
                                  {
                                      languageStatus === 'en' ?
                                          <>
                                                      {label ? `${(label.designation).trim()}'s Speech` : null}
                                                    </>
                                          :
                                          <>
                                                    {label ? `${(label.designationNP).trim()}को मन्तव्य` : null}
                                                  </>
                                  }
                              </h2>
                              {messages.length > 0 ? (
                                  <p
                                      className="text-justify"
                                      dangerouslySetInnerHTML={parseHtmlToView(
                                          languageStatus === 'en' ? (messages[0].message ?? messages[0].messageNp) : (messages[0].messageNp ?? messages[0].message)
                                      )}
                                  />
                              ) : null}
                          </div>
                      </div>
                  </div>
                  <div className="col-md-4 d-flex flex-column justify-content-center">
                      {/*<div*/}
                      {/*    className=" align-items-stretch position-absolute"*/}
                      {/*    style={{top: '5rem', right:'8rem', zIndex:'-1'}}*/}
                      {/*>*/}
                          <div
                              className="img-fluid"
                          >
                            <img
                                className="border mr-3 rounded message-image w-100"
                                src={
                                    label && label.profileImg !== "N/A"
                                        ? label.profileImg
                                        : `../assets/images/placeholder.jpg`
                                }
                                alt=""
                            />
                          </div>
                      {/*</div>*/}
                  </div>
              </div>
          </div>
      </section>
        </>
    );
}

export default Message;
