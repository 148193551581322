import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import ApiService from "../../network/ApiService";

function About() {
    const {
        fetchAboutUs,
    } = ApiService();

    const {organizationId} = useSelector((state) => state.organization);
    const languageStatus = sessionStorage.getItem("language");
    const [aboutUsData, setAboutUsData] = useState([]);
    const [messages, setMessages] = useState({});
    const [messageDetails, setMessageDetails] = useState({});

    const getAboutUs = async () => {
        try {
            const response = await fetchAboutUs();
            setAboutUsData(response.data.aboutUsDetails);
        } catch (error) {
            console.log(error.message);
        }
    };

    const parseHtmlToView = (str) => {
        return {__html: str};
    };

    useEffect(() => {
        if (organizationId) {
            getAboutUs();
        }
    }, [organizationId]);

    useEffect(() => {
        if (aboutUsData.length > 0) {
            aboutUsData.length = 1;
            setAboutUsData(aboutUsData);
        }
    }, [aboutUsData]);

    return (
        <>
      <section className="section-padding section-bg" id="section_2">
		<div className={window.location.pathname === '/' ? `container` : 'container about-container'}>
			<div className="row no-gutters">
                <div className="col-lg-8 col-12">
					<div className="bg-light px-3 px-md-4 py-5 ">
						<div className="heading-section">
							<h2 className="mb-3">
								{
                                    languageStatus === 'en' ?
                                        (aboutUsData[0]?.header ? aboutUsData[0]?.header : aboutUsData[0]?.headerNp)
                                        : (aboutUsData[0]?.headerNp ? aboutUsData[0]?.headerNp : aboutUsData[0]?.header)
                                }
							</h2>

							<p
                                className="text-justify"
                                dangerouslySetInnerHTML={parseHtmlToView(
                                    (languageStatus === 'en' ? (aboutUsData[0]?.message !== '' ? aboutUsData[0]?.message : aboutUsData[0]?.messageNp) : ((aboutUsData[0]?.messageNp !== '' && aboutUsData[0]?.messageNp) ? aboutUsData[0]?.messageNp : aboutUsData[0]?.message))
                                )}
                            ></p>
						</div>
					</div>
				</div>
				<div className="col-lg-4 col-12 mb-5 mb-lg-0 d-flex flex-column justify-content-center">
                    <img
                        src={aboutUsData[0]?.image && aboutUsData[0]?.image.trim() !== "" ? `${aboutUsData[0]?.image}` : "assets/images/placeholder.jpg"}
                        className="custom-text-box-image img-fluid" alt=""
                    />
				</div>

			</div>
		</div>
	</section>
    </>
    );
}

export default About;
