import React from "react";
import "./Team.css";
import engToNepNumber from "../../../helpers/EngToNepNumber";

function Staff(props) {
  const staff = props.staffs;
  const languageStatus = sessionStorage.getItem("language");
  return (
      <div className="">
      <div>
        <div className="member">
          <div className="member-img">
            <img
                src={
                  staff.photoUrl ? staff.photoUrl : "assets/images/placeholder.jpg"
                }
                className="img-fluid"
                alt=""
            />
          </div>
          <div className="member-info">
            <h4>{languageStatus === 'en' ? (staff.name ?? staff.nameNp) : (staff.nameNp ?? staff.name)}</h4>
            {staff.staffDesignationNp ? (
                <>
                <span>{languageStatus === 'en' ? (staff.staffDesignation ?? staff.staffDesignationNp) : (staff.staffDesignationNp ?? staff.staffDesignation)}</span>
              </>
            ) : null}
            {staff.staffContactNumber1 ? (
                <>
                <span>{languageStatus === 'en' ? staff.staffContactNumber1 : engToNepNumber(staff.staffContactNumber1)}</span>
              </>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Staff;
