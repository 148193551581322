import React, {useState} from "react";
import {useSelector} from "react-redux";
import ApiService from "../../../../network/ApiService";
import engToNepNumber from "../../../../helpers/EngToNepNumber";

function OutStandingReport() {
    const {organizationId} = useSelector((state) => state.organization);
    const {
        fetchOutStanding,
    } = ApiService();

    const [OutStandingReport, setOutStandingReport] = useState({});
    const [customerNo, setCustomerNo] = useState("");
    const [messageDetail, setMessageDetail] = useState(null);
    const languageStatus = sessionStorage.getItem("language");
    const getOutStandingReport = async () => {
        try {
            const response = await fetchOutStanding(customerNo);
            setOutStandingReport(response.data);
            if (Object.keys(response.data).length === 0) {
                languageStatus === 'en' ? setMessageDetail("Customer Detail Not Found...") :
                    setMessageDetail("ग्राहक विवरण भेटियन...");
            } else {
                languageStatus === 'en' ? setMessageDetail("Customer Detail Not Found...") :
                    setMessageDetail("ग्राहक विवरण भेटियन...");
            }
        } catch (error) {
            setOutStandingReport({});
            languageStatus === 'en' ? setMessageDetail("Customer Detail Not Found...") :
                setMessageDetail("ग्राहक विवरण भेटियन...");
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (organizationId) {
            getOutStandingReport();
        }
    };
    return (
        <section className="section-padding mb-4">
      <div className="container">
        <div className="col-lg-12 col-12 text-center mb-4">
          <h2>{languageStatus === 'en' ? `Outstanding Details` : `बक्यौता विवरण`}</h2>
        </div>
        <div className="row no-gutters outstanding-header">
          <div className="card" style={{width: "100%"}}>
            <div className="card-header">
              <form onSubmit={(e) => handleSubmit(e)}>
                <div className="row g-3 align-items-center m-auto">
                  <div className="col-auto">
                    <input
                        type="text"
                        id="customerNo"
                        className="form-control"
                        value={customerNo}
                        onChange={(e) => setCustomerNo(e.target.value)}
                        placeholder={languageStatus === 'en' ? `Customer No.` : `ग्राहकको कोड`}
                    />
                  </div>
                  <div className="col-auto">
                    <button type="submit" className="btn btn-primary btn-sm" style={{background: '#05C3DD'}}>
                      {languageStatus === 'en' ? `Search` : `खोज्नुहोस`}
                    </button>
                  </div>
                </div>
              </form>
            </div>
              {Object.keys(OutStandingReport).length > 0 ? (
                  <div
                      className="card-body"
                      style={{
                          lineHeight: 3,
                          color: "black",
                          paddingTop: 0,
                      }}
                  >
                <div className="col-md-6">
                <fieldset className="border p-2 rounded">
                  <legend className="float-none w-auto pr-2 pl-2">
                    {languageStatus === 'en' ? `Customer's Detail` : `ग्राहक विवरण`}
                  </legend>
                  <div>
                    <span className="font-weight-bold p-1">{languageStatus === 'en' ? `Name` : `नाम `}: </span>
                    <span>{languageStatus === 'en' ? OutStandingReport.fullName : OutStandingReport.fullNameNp}</span>
                  </div>
                  <div>
                    <span className="font-weight-bold p-1">{languageStatus === 'en' ? `Address` : `ठेगाना `}: </span>
                    <span>{languageStatus === 'en' ? OutStandingReport.address : OutStandingReport.addressNp}</span>
                  </div>
                  <div style={{backgroundColor: "#eb5d1e", color: 'white'}}>
                    <span className="font-weight-bold p-1">
                      {languageStatus === 'en' ? `Amount to be Paid` : `जम्मा भुक्तानी गर्ने रकम`} :
                    </span>
                    <span className="ms-2">
                      <strong>
                          {
                              languageStatus === 'en' ?
                                  <>
                                      Rs. {OutStandingReport.totalPayable}
                                  </>
                                  :
                                  <>
                                      रु. {engToNepNumber(OutStandingReport.totalPayable)}
                                  </>
                          }
                      </strong>
                    </span>
                  </div>
                </fieldset>
                </div>
              </div>
              ) : (
                  <div>
                {messageDetail ? (
                    <h4 className="text-center message">{messageDetail}</h4>
                ) : null}
              </div>
              )}
          </div>
        </div>
      </div>
    </section>
    );
}

export default OutStandingReport;
