import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {Link} from "react-router-dom";
import ApplicationHelper from "../../../helpers/ApplicationHelper";
import ApiService from "../../../network/ApiService";

function Notices(props) {
    const {organizationId} = useSelector((state) => state.organization);
    const languageStatus = sessionStorage.getItem("language");
    const {
        fetchNoticeUpdates,
    } = ApiService();

    const [newsList, setNewsList] = useState([]);
    const [splicedNews, setSplicedNews] = useState([]);
    const getNewsList = async () => {
        try {
            const response = await fetchNoticeUpdates();
            if (window.location.pathname === '/') {
                setNewsList((response.data.aboutUsNewsAndImg).filter((a, b) => {
                    return b < 4;
                }))
            } else {
                setNewsList(response.data.aboutUsNewsAndImg);
            }
        } catch (error) {
            console.log(error.message);
        }
    };

    const parseHtmlToView = (str) => {
        return {__html: str};
    };

    useEffect(() => {
        if (organizationId) {
            getNewsList();
        }
    }, [organizationId]);

    useEffect(() => {
        if (props.newsDetailId) {
            const newNewsList = [...newsList];
            const filteredList = newNewsList.filter((news) => {
                return news.newsId !== Number(props.newsDetailId);
            });

            if (filteredList.length > 3) {
                filteredList.length = 3;
            }
            setSplicedNews(filteredList);
        }
    }, [newsList, props.newsDetailId]);

    return (
        <>
            <section className={window.location.pathname !== '/' ? `section-padding` : (window.location.pathname === '/' && newsList.length <= 0 ? 'd-none' : 'section-padding')} id="section_3">
                <div className="container">
                    <div className="row col-md-12">
                        <div className="row mb-4">
                            <h2 className="col-md-9 news-notice-header">{languageStatus === 'en' ? 'Notices' : `सूचना तथा गतिबिधि`}</h2>
                            <span className={window.location.pathname === '/' ? 'col-md-3 ' : 'd-none'}>
                                <Link
                                    to="/notice"
                                    className="btn btn-success"
                                    style={{top: '0', right: '0.25rem', color: 'white'}}
                                >
                                      {languageStatus === 'en' ? `View All` : `सबै हेर्नुहोस्`}
                                </Link>
                              </span>
                        </div>
                        {
                            newsList.length > 0 ? (
                                <>
                                    {
                                        newsList.map((data, index) => {
                                            return (
                                                <div className={`col-md-6 col-12 mb-4 mb-lg-0 text-center news-featured-block ${window.location.pathname === '/' ? 'col-lg-4' : 'col-lg-2'}`}>
                                                <div className="news-custom-block-wrap news-featured-block-image">
                                                    <Link
                                                        to={`/notice-detail/${data.newsId}`}
                                                        className=" mr-4"
                                                    >
                                                    <img
                                                        src={data.imageUrl !== 'n/a' ? `${data.imageUrl}` : `'assets/images/placeholder.jpg'`}
                                                        className="news-featured-block-image img-fluid" alt=""
                                                    />
                                                    </Link>

                                                    <div className="news-custom-block">
                                                        <div className="news-custom-block-body">
                                                            <h6 className="mb-3 news-featured-block-image">
                                                                <Link
                                                                    to={`/notice-detail/${data.newsId}`}
                                                                >
                                                                    {(languageStatus === 'en' ? (data.header !== '' ? data.header : data.headerNp) : ((data.headerNp !== '' && data.headerNp) ? data.headerNp : data.header))}
                                                                </Link>
                                                            </h6>
                                                        </div>
                                                        <Link
                                                            className="custom-btn btn"
                                                            to={`/notice-detail/${data.newsId}`}
                                                        >
                                                            {languageStatus === 'en' ? `See Details` : `विवरण हेर्नुहोस्`}
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                            )
                                        })
                                    }
                                </>
                            ) :
                                <>
                                    <div style={{border: '1px solid', borderRadius: '5px', padding: '5px'}}>
                                        <div
                                            className="col-md-12"
                                            style={{fontSize: '24px'}}
                                        >
                                            {languageStatus === 'en' ? `Notices Not Found` : `सूचना तथा गतिबिधि भेटियन`}...
                                        </div>
                                    </div>
                                </>
                        }
                    </div>
                </div>
            </section>
        </>
    );
}

export default Notices;
