import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {Link, useParams} from "react-router-dom";
import ApiService from "../../../network/ApiService";
import engToNepNumber from "../../../helpers/EngToNepNumber";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";

function NewsDetail() {
    const {
        fetchNewsUpdates,
        fetchNewsDetailById,
    } = ApiService();

    const {organizationId} = useSelector((state) => state.organization);
    const languageStatus = sessionStorage.getItem("language");
    const [newsDetail, setNewsDetail] = useState({});
    const [newsList, setNewsList] = useState([]);
    const [open, setOpen] = useState(false);
    const [slicedNews, setSlicedNews] = useState([]);
    const [slides, setSlides] = useState([]);
    const params = useParams();
    const newsId = params.newsId;
    const getNewsDetails = async () => {
        try {
            const response = await fetchNewsDetailById(newsId);
            setNewsDetail(response.data.aboutUsNewsAndImg);
        } catch (error) {
            console.log(error.message);
        }
    };

    const getNews = async () => {
        try {
            const response = await fetchNewsUpdates();
            setNewsList(response.data.aboutUsNewsAndImg);
        } catch (error) {
        }
    };

    const parseHtmlToView = (str) => {
        return {__html: str};
    };

    useEffect(() => {
        if (organizationId && newsId) {
            getNewsDetails();
        }
    }, [organizationId, newsId]);

    useEffect(() => {
        getNews();
    }, [newsDetail]);

    useEffect(() => {
        if (newsList.length > 0 && newsId) {
            const newList = [...newsList];
            const filtered = newList.filter((item) => {
                return item.newsId !== Number(newsId);
            });

            if (filtered.length > 3) {
                filtered.length = 3;
            }
            setSlicedNews(filtered);
        }
    }, [newsList]);

    const handleViewSlides = (galleryImages) => {
        const images = [];
        galleryImages.map((value, item) => {
            images.push({
                src: value,
                alt: "image",
                width: 3840,
                height: 2560,
            });
        });
        setSlides(images);
        setOpen(!open);
    };

    return (
        <>
            <section className="news-section section-padding">
                <div className="container">
                    <div className="row">
                        <div className="col-8"><h2>{languageStatus === 'en' ? 'News' : `समाचार`}</h2></div>
                        {slicedNews.length > 0 ? (
                            <div className="col-4"><h2>{languageStatus === 'en' ? `Latest Notices` : `ताजा समाचार`}</h2></div>
                        ) : null}
                    </div>
                    <div className="row">
                        <div className={slicedNews.length > 0 ? 'col-8' : 'col-12'}>
                            {newsDetail.length > 0 ? (
                                <>
                                    <p>
                                        <img
                                            src={
                                                newsDetail[0].imageUrl !== "n/a"
                                                    ? newsDetail[0].imageUrl
                                                    : `assets/images/placeholder.jpg`
                                            }
                                            style={{
                                                width: slicedNews.length > 0 ? '' : '20%'
                                            }}
                                            className="news-images img-fluid"
                                            alt=""
                                        />
                                    </p>
                            <p className="font-italic mb-3">
                                {newsDetail[0] ? (languageStatus === 'en' ? newsDetail[0].newsDateEn : engToNepNumber(newsDetail[0].newsDate)) : null}
                            </p>
                            <h4>
                                <a href="#" className="news-block-title-link">
                                    {newsDetail[0] ? (languageStatus === 'en' ? (newsDetail[0].header ?? newsDetail[0].headerNp) : (newsDetail[0].headerNp ?? newsDetail[0].header)) : null}
                                </a>
                            </h4>
                            <p
                                dangerouslySetInnerHTML={parseHtmlToView(
                                    (languageStatus === 'en' ? ((newsDetail[0].message && newsDetail[0].message !== '') ? newsDetail[0].message : newsDetail[0].messageNp) : ((newsDetail[0].messageNp && newsDetail[0].messageNp !== '') ? newsDetail[0].messageNp : newsDetail[0].message))
                                )}
                            ></p>
                                    <div>
                                        {
                                            (newsDetail[0].galleryUrls).length > 0 ?
                                                <>
                                      <div className="flex flex-column">
                                          <h3 className="text-center">{languageStatus === 'en' ? `Related Images` : 'सम्बन्धित चित्रहरू'}</h3>
                                          <div className="d-flex justify-content-center news-gallery-image">
                                              <Lightbox
                                                  open={open}
                                                  close={() => setOpen(!open)}
                                                  slides={slides}
                                                  plugins={[Thumbnails]}
                                                  carousel={{finite: true}}
                                              />
                                              {
                                                  (newsDetail[0].galleryUrls).length > 0 ?
                                                      <>
                                                          <div
                                                              className="card col-6 mb-5 news-gallery-image m-1 overflow-hidden"
                                                              onClick={() => handleViewSlides(newsDetail[0].galleryUrls)}
                                                          >
                                                              <img
                                                                  src={
                                                                      newsDetail[0].galleryUrls && newsDetail[0].galleryUrls !== "n/a"
                                                                          ? newsDetail[0].galleryUrls
                                                                          : "assets/images/placeholder.jpg"
                                                                  }
                                                              />
                                                          </div>
                                                      </> : null
                                              }
                                          </div>
                                      </div>
                                  </> : null
                                        }
                                    </div>
                                </>
                            ) : null
                            }
                        </div>
                        {slicedNews.length > 0 ? (
                            <>
                                <div className="col-lg-4 col-12 mx-auto mt-4 mt-lg-0">
                                    {slicedNews.map((data, index) => {
                                        return (
                                            <>
                                                <div className="news-block news-block-two-col mt-4 featured-block w-50 text-center"
                                                     style={{
                                                         padding: '1rem'
                                                     }}>
                                                    <div className="news-block-two-col-image-wrap ">
                                                        <Link
                                                            to={`/news-detail/${data.newsId}`}
                                                            className=" mr-4"
                                                        >
                                                            <img
                                                                src={
                                                                    data.imageUrl !== "n/a"
                                                                        ? data.imageUrl
                                                                        : `../assets/images/placeholder.jpg`
                                                                }
                                                                className="featured-block-image img-fluid"
                                                            />
                                                        </Link>
                                                    </div>

                                                    <div className="news-block-two-col-info">
                                                        <div className="featured-block-text mb-2">
                                                            <h6><Link
                                                                to={`/news-detail/${data.newsId}`}
                                                                className="news-block-title-link"
                                                            >{languageStatus === 'en' ? (data.header ?? data.headerNp) : (data.headerNp ?? data.header)}</Link>
                                                            </h6>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    })
                                    }
                                </div>
                            </>
                        ) : null
                        }
                    </div>
                </div>
            </section>
        </>
    );
}

export default NewsDetail;
