import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {Link} from "react-router-dom";
import ApiService from "../../network/ApiService";
import { Autoplay, EffectCube, EffectFlip, Navigation, Pagination, Parallax, Scrollbar } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/bundle";

function Hero() {
    const {orgDetailData} = useSelector((state) => state.contact);
    const { organizationId } = useSelector((state) => state.organization);
    const languageStatus = sessionStorage.getItem("language");
    const [sliderImages, setSliderImages] = useState([]);

    const {
        fetchSliderImages,
    } = ApiService();

    const getSliderImages = async () => {
        try {
            const response = await fetchSliderImages();
            setSliderImages(response.data.imagesList);
        } catch (error) {
            console.log(error.message);
        }
    };

    useEffect(() => {
        if (organizationId) {
            getSliderImages();
        }
    }, [organizationId]);

    return (
        <>
            {/*<div*/}
            {/*    className="hero-section hero-section-full-height"*/}
            {/*    style={{backgroundImage: "url('/assets/images/bg_1.jpg')"}}*/}
            {/*    data-stellar-background-ratio="0.5"*/}
            {/*>*/}
            {/*    <div className="overlay"></div>*/}
            {/*    <div className="container">*/}
            {/*        <div className="row no-gutters slider-text align-items-center">*/}
            {/*            <div className="col-11 ftco-animate d-flex align-items-end">*/}
            {/*                <div className="text w-100">*/}
            {/*                    <h1 className="mb-4">*/}
            {/*                        {*/}
            {/*                            orgDetailData ? (languageStatus === 'en' ? (orgDetailData.nameEnglish ?? orgDetailData.nameNepali) : (orgDetailData.nameNepali ?? orgDetailData.nameEnglish)) : null*/}
            {/*                        }*/}
            {/*                    </h1>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*            /!*<Link*!/*/}
            {/*            /!*    to="/about"*!/*/}
            {/*            /!*    className="img-video popup-vimeo d-flex align-items-center justify-content-center"*!/*/}
            {/*            /!*>*!/*/}
            {/*            /!*    <span className="fa fa-play"></span>*!/*/}
            {/*            /!*</Link>*!/*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}
            <section className="hero-section hero-section-full-height">
                {sliderImages.length > 0 ?
                    <>
                        <Swiper
                            className="hero-wrap swiper"
                            modules={[Navigation, Pagination, Scrollbar,Autoplay,EffectFlip]}
                            // spaceBetween={50}
                            // slidesPerView={3}
                            navigation
                            // pagination={{ clickable: true }}
                            scrollbar={{ draggable: false }}
                            autoplay={{ delay:5000 }}
                            flipEffect={{ slideShadows:false}}
                        >
                            {sliderImages.length > 0
                                ? sliderImages.map((data, index) => {
                                    return (
                                        <SwiperSlide
                                            key={index}
                                            className="swiper-slide-width"
                                            style={{
                                                width: "300px",
                                                // height:"300px",
                                            }}
                                        >
                                            <img
                                                className="swiper-img"
                                                src={data}
                                                alt={`slider${index + 1}`}
                                            />
                                        </SwiperSlide>
                                    );
                                })
                                : null}
                        </Swiper>
                    </>
                    : null
                }
            </section>
        </>
    );
}

export default Hero;
