import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import ApiService from "../../../../network/ApiService";
import "./Schedule.css";
import engToNepNumber from "../../../../helpers/EngToNepNumber";
import engToNepDay from "../../../../helpers/EngToNepDay";
import engToNepTimeOfDay from "../../../../helpers/EngToNepTimeOfDay";

function ScheduleList() {
    const {organizationId} = useSelector((state) => state.organization);
    const {
        fetchScheduleList,
    } = ApiService();

    const [scheduleList, setScheduleList] = useState([]);
    const languageStatus = sessionStorage.getItem("language");
    const getSchedule = async () => {
        try {
            const response = await fetchScheduleList();
            setScheduleList(response.data.masterList);
        } catch (error) {
            console.log(error.message);
        }
    };

    useEffect(() => {
        if (organizationId) {
            getSchedule();
        }
    }, [organizationId]);
    return (
        <section className="section-padding mt-3 mb-4">
      <div className="container">
        <div className="col-lg-12 col-12 text-center mb-4">
          <h4>{languageStatus === 'en' ? `Water Distribution Schedule` : `पानी बितरण तालिका`}</h4>
        </div>
        <div className="row no-gutters">
          <div className="col-md-12 d-flex align-items-stretch">
              <div className="col-md-12">
            {
                scheduleList.length > 0 ?
                    scheduleList.map((data, index) => {
                        return (
                            <>
                          {
                              Object.keys(data.scheduleDetailList).map((lastData, lastIndex) => {
                                  return (
                                      <div className="mt-2 card" key={`card_` + lastIndex}>
                                      <div className="card-header water-schedule-detail-card-header-one">
                                          <div className="d-flex">
                                             <div className="">{languageStatus === 'en' ? `Source` : 'श्रोत'}</div> -
                                              <div className="text-center">{
                                                  languageStatus === 'en' ?
                                                      data.scheduleDetailList[lastData][index]['secSource']
                                                      : data.scheduleDetailList[lastData][index]['secSourceNp']
                                              }</div>
                                          </div>
                                        </div>
                                        <div className="card-header water-schedule-detail-card-header-two">
                                          <div className="d-flex">
                                             <div className="">{languageStatus === 'en' ? `Day Part` : `दिनको भाग`}</div>
                                              &nbsp; - &nbsp;
                                              <div className="text-center">
                                                  {
                                                      languageStatus === 'en' ?
                                                          lastData
                                                          : engToNepTimeOfDay(lastData)
                                                  }
                                              </div>
                                          </div>
                                        </div>
                                      <div className="card-body water-schedule-detail">
                                          <table className="table water-schedule-detail-table">
                                              <tbody>
                                              {
                                                  data.scheduleDetailList[lastData].map((list, listIndex) => {
                                                      return (
                                                          <>
                                                              {
                                                                  list.fromTime !== undefined || list.toTime !== undefined ?
                                                                      <tr key={`data_` + listIndex}>
                                                                          <td>{languageStatus === 'en' ? (list.weekDay ?? engToNepDay(list.weekDay)) : (engToNepDay(list.weekDay) ?? list.weekDay)}</td>
                                                                          <td><strong>{languageStatus === 'en' ? list.fromTime + ' - ' + list.toTime : engToNepNumber(list.fromTime) + ' - ' + engToNepNumber(list.toTime)}</strong></td>
                                                                      </tr>
                                                                      : null
                                                              }
                                                              </>
                                                      )
                                                  })
                                              }
                                            </tbody>
                                          </table>
                                      </div>
                                  </div>
                                  );
                              })
                          }
                      </>
                        )
                    })
                    : <>
                      <div style={{border: '1px solid', borderRadius: '5px', padding: '5px'}}>
                          <div
                              className="uk-flex-wrap-bottom uk-text-bold"
                              style={{fontSize: '24px'}}
                          >
                              {languageStatus === 'en' ? `Schedule Not Found` : `बितरण तालिका भेटियन`}...
                          </div>
                      </div>
                  </>
            }
          </div>
          </div>
        </div>
      </div>
    </section>
    );
}

export default ScheduleList;
