import React, { useEffect, useState } from "react";
// import Popup from "./includes/Popup";
import About from "./pages/About";
import News from "./pages/news/News";
import Notices from "./pages/notices/Notices";
import Hero from "./pages/Hero";
// import PaymentGateway from "./pages/eservices/paymentGateway/PaymentGateway";
// import Feature from "./pages/Feature";
// import Hero from "./pages/Hero";
// import HeroNew from "./pages/HeroNew";
// import MessageNew from "./pages/MessageNew";
// import News from "./pages/news/News";

function Home() {
  const [popupVisible, setPopupVisible] = useState(false);
  const languageStatus = sessionStorage.getItem("language");

  useEffect(() => {
    const popupStatus = sessionStorage.getItem("popup");
    if (!popupStatus) {
      setPopupVisible(true);
      sessionStorage.setItem("popup", 1);
    }
  }, []);

  return (
    <div>
      <Hero />
      <About />
      <div className="container d-flex">
        <div className="col-6 news-border">
          <News/>
        </div>
        <div className="col-6">
          <Notices/>
        </div>
      </div>
      {/*<Feature />*/}
      {/*<PaymentGateway />*/}
      {/*{popupVisible ? (*/}
      {/*  <div className="d-flex justify-content-between">*/}
      {/*    <div>*/}
      {/*      <Popup />*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*) : null}*/}
    </div>
  );
}

export default Home;
