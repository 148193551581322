import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ApiService from "../../network/ApiService";
import engToNepNumber from "../../helpers/EngToNepNumber";
import DownloadDetails from "./DownloadDetails";
import {Link} from "react-router-dom";

function Download() {
  const {
    fetchDownloads,
  } = ApiService();

  const { organizationId } = useSelector((state) => state.organization);
  const [downloadDocuments, setDownloadDocuments] = useState([]);
  const [aboutUsId, setAboutUsId] = useState(null);
  const languageStatus = sessionStorage.getItem("language");
  const getDownloadDocuments = async () => {
    try {
      const response = await fetchDownloads();
      setDownloadDocuments(response.data.messageWithImg);
    } catch (error) {
      console.log(error.message);
    }
  };

  const parseHtmlToView = (str) => {
    return { __html: str };
  };

  useEffect(() => {
    if (organizationId) {
      getDownloadDocuments();
    }
  }, [organizationId]);

  return (
    <>
        <section className="section-padding">
          <div className="container">
            { aboutUsId === null ?
            <>
            <div className="section-title">
              <h2>{ languageStatus === 'en' ? `Download` : `डाउनलोड`}</h2>
            </div>
            <div className="row no-gutters">
              <div className="col-md-12 d-flex align-items-stretch">
                <table className="table table-striped">
                  <thead>
                        <th>{ languageStatus === 'en' ? 'S.No' : 'क्रम संख्या'}</th>
                        <th>{ languageStatus === 'en' ? 'Document Name' : 'कागजातको नाम'}</th>
                        <th>{ languageStatus === 'en' ? 'View' : 'अवलोकन'}</th>
                    </thead>
                  <tbody>
                    {downloadDocuments.length > 0
                      ? downloadDocuments.map((data, index) => {
                          return (
                            <tr key={index}>
                              <th>{languageStatus === 'en' ? index + 1 : engToNepNumber(index + 1)}</th>
                              {/* {console.log(data.message,'sddfn')} */}
                              <td>{languageStatus === 'en' ? (data.header ?? data.headerNp) : (data.headerNp ?? data.header)}</td>
                              <td>
                                {data.documentWithNameList.length > 0 ?
                                    <>
                                    <Link onClick={() => setAboutUsId(data.aboutUsId)}
                                       style={{
                                         fontSize: "20px",
                                         cursor: "pointer"
                                       }}>
                                      <i className="fa fa-eye"></i>
                                    </Link>

                                    </>
                                    :
                                    <div style={{fontStyle: 'italic'}}>
                                        { languageStatus === 'en' ? `No File Available for Download` : `डाउनलोड फाइल भेटियन`}...
                                    </div>
                                }
                              </td>
                            </tr>
                          );
                        })
                      : <tr>
                              <td
                                  colSpan={4}
                                  className="uk-text-bold"
                                  style={{fontSize: '20px'}}
                              >
                                  { languageStatus === 'en' ? `No File Available for Download` : `डाउनलोड फाइल भेटियन`}...
                              </td>
                            </tr>
                    }
                  </tbody>
                </table>
              </div>
              <div className="col-md-2 d-flex align-items-stretch">
                {/* <News newsDetails={true} />  */}
              </div>
            </div>
              </>
            : <DownloadDetails detailId={aboutUsId} setAboutUsId={setAboutUsId}/>
            }
          </div>
        </section>
    </>
  );
}

export default Download;
