import React from "react";
import {useEffect} from "react";
import {useState} from "react";
import {useSelector} from "react-redux";
import {Link} from "react-router-dom";

function Footer() {
    const {contactListData, orgDetailData} = useSelector(
        (state) => state.contact
    );
    const [phone, setPhone] = useState(null);
    const [email, setEmail] = useState(null);
    const [orgDetail, setOrgDetail] = useState(null);
    const languageStatus = sessionStorage.getItem("language");

    useEffect(() => {
        if (contactListData && Object.keys(contactListData).length > 0) {
            setPhone(contactListData.find((item) => item.contactType === "Phone"));
            setEmail(contactListData.find((item) => item.contactType === "Email"));
        }
    }, [contactListData]);

    useEffect(() => {
        if (orgDetailData && Object.keys(orgDetailData).length > 0) {
            setOrgDetail(orgDetailData);
        }
    }, [orgDetailData]);

    // const parseHtmlToView = (str) => {
    //   return { __html: str };
    // };

    return (
        <>
            <footer className="site-footer">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 col-md-6 col-12 mb-4">
                            {
                                orgDetail?.bannerImage ? <>
                                    <img
                                        src={
                                            orgDetail?.bannerImage
                                                ? orgDetail?.bannerImage
                                                : null
                                        }
                                        className="logo img-fluid" alt="" />
                                </> : null
                            }
                            <br />
                            <ul className="footer-menu">
                            <li className="footer-menu-item-organization">
                                {orgDetail ? (languageStatus === 'en' ? (orgDetail?.nameEnglish ?? orgDetail?.nameNepali) : (orgDetail?.nameNepali ?? orgDetail?.nameEnglish)) : null} <br />
                                {orgDetail ? (languageStatus === 'en' ? (orgDetail?.addressEnglish ?? orgDetail?.addressNepali) : (orgDetail?.addressNepali ?? orgDetail?.addressEnglish)) : null} <br />
                            </li>
                            </ul>
                        </div>

                        <div className="col-lg-3 col-md-6 col-12 mb-4">
                            <h5 className="site-footer-title mb-3">{languageStatus === 'en' ? `Link` : `लिङ्क`}</h5>

                            <ul className="footer-menu">
                                <li className="footer-menu-item"><a href="#" className="footer-menu-link">{languageStatus === 'en' ? `Organization Information` : `संस्थाको जानकारी`}</a></li>

                                <li className="footer-menu-item"><a href="#" className="footer-menu-link">{languageStatus === 'en' ? `News & Notices` : `सुचना/गतिविधि`}</a></li>

                                <li className="footer-menu-item"><a href="#" className="footer-menu-link">{languageStatus === 'en' ? `Contact` : `सम्पर्क`}</a></li>

                            </ul>
                        </div>

                        <div className="col-lg-3 col-md-6 col-12 mb-4">
                            <h5 className="site-footer-title mb-3">{languageStatus === 'en' ? `Other Links` : `अतिरित्त लिङ्क`}</h5>

                            <ul className="footer-menu">
                                <li className="footer-menu-item">
                                    <a href="http://www.mowss.gov.np/" target="_blank" className="footer-menu-link"
                                >
                                    {languageStatus === 'en' ? `Ministry of Water Supply` : `खानेपानी मन्त्रालय`}
                                </a></li>

                                <li className="footer-menu-item"><a href="http://www.nwsc.gov.np/" target="_blank" className="footer-menu-link">
                                    {languageStatus === 'en' ? `Department of Water Supply` : `खानेपानी बिभाग`}
                                </a></li>

                                <li className="footer-menu-item"><a href="http://www.tdf.org.np/" target="_blank" className="footer-menu-link">
                                    {languageStatus === 'en' ? `Municipal Development Fund` : `नगर विकास कोष`}
                                </a></li>
                            </ul>
                        </div>

                        <div className="col-lg-3 col-md-6 col-12">
                            <h5 className="site-footer-title mb-3">{languageStatus === 'en' ? `Contact` : `सम्पर्क`}</h5>

                            {
                                orgDetail?.contactNumberNp ?
                                    <p className="text-white d-flex mb-2">
                                        <i className="bi-telephone me-2"></i>
                                        <a href={`tel:${orgDetail ? orgDetail?.contactNumberNp : ""}`} className="site-footer-link">
                                            {orgDetail ? (languageStatus === 'en' ? (orgDetail?.contactNumber ?? orgDetail?.contactNumberNp) : (orgDetail?.contactNumberNp ?? orgDetail?.contactNumber)) : null}
                                        </a>
                                    </p>
                                    : null
                            }
                            {
                                email?.contactAddress ?
                                    <p className="text-white d-flex">
                                        <i className="bi-envelope me-2"></i>

                                        <a href={`mailto:${email?.contactAddress}`} className="site-footer-link">
                                            {email?.contactAddress}
                                        </a>
                                    </p>
                                    : null
                            }
                            {
                                (orgDetail?.nameEnglish || orgDetail?.nameEnglish) ?
                                    <p className="text-white d-flex mt-3">
                                        <i className="bi-geo-alt me-2"></i>
                                        {orgDetail ? (languageStatus === 'en' ? (orgDetail?.nameEnglish ?? orgDetail?.nameNepali) : (orgDetail?.nameNepali ?? orgDetail?.nameEnglish)) : null}
                                        <br />
                                        {orgDetail ? (languageStatus === 'en' ? (orgDetail?.addressEnglish ?? orgDetail?.addressNepali) : (orgDetail?.addressNepali ?? orgDetail?.addressEnglish)) : null}
                                        <br />
                                    </p>
                                    : null
                            }
                            <Link to="/contact" className="custom-btn btn mt-3">{languageStatus === 'en' ? 'Go to Map' :`नक्सामा जानुहोस्`}</Link>
                        </div>
                    </div>
                </div>

                <div className="site-footer-bottom">
                    <div className="container">
                        <div className="row">

                            <div className="col-lg-4 col-md-7 col-12">
                                <p className="copyright-text mb-0">&copy;
                                    {new Date().getFullYear()}{" "} {orgDetail ? (languageStatus === 'en' ? (`All Rights Reserved ` + orgDetail?.nameEnglish) : (orgDetail?.nameNepali + `| सम्पूर्ण अधिकार सुरक्षित`)) : null}
                                </p>
                            </div>


                            <div className="col-lg-4 col-md-5 col-12 d-flex justify-content-center align-items-center mx-auto">
                                <ul className="social-icon">
                                    <li className="social-icon-item">
                                        <a href={orgDetail ? orgDetail?.twitterUrl : ""} target="_blank" className="social-icon-link bi-twitter"></a>
                                    </li>

                                    <li className="social-icon-item">
                                        <a href={orgDetail ? orgDetail?.facebookUrl : ""} target="_blank" className="social-icon-link bi-facebook"></a>
                                    </li>

                                    <li className="social-icon-item">
                                        <a href={orgDetail ? orgDetail?.linkedInUrl : ""} target="_blank" className="social-icon-link bi-linkedin"></a>
                                    </li>

                                    <li className="social-icon-item">
                                        <a href={orgDetail ? orgDetail?.youtubeUrl : ""} target="_blank" className="social-icon-link bi-youtube"></a>
                                    </li>
                                </ul>
                            </div>


                            <div className="col-lg-4 col-md-7 col-12">
                                <p className="copyright-text mb-0">
                                    {languageStatus === 'en' ? 'Designed and Developed By : ' : `व्यबसाईट निर्माता : `} <a
                                    target="_blank"
                                    href="https://diyalotech.com/"
                                >{languageStatus === 'en' ? `Diyalo Technologies` : ` दियालो टेक्नोलोजी`}</a>
                                </p>
                            </div>

                        </div>
                    </div>
                </div>
            </footer>
            <style type="text/css">
                { orgDetailData?.customCss}
            </style>
        </>
    );
}

export default Footer;
