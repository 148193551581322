import React from "react";
import { Link } from "react-router-dom";
import engToNepNumber from "../../helpers/EngToNepNumber";

function NotFoundPage() {
  const languageStatus = sessionStorage.getItem("language");
  return (
    <main id="main">
      <div className="d-flex align-items-center justify-content-center my-5">
        <div className="text-center">
          <h1 className="display-1 fw-bold">{languageStatus === 'en' ? `404` : engToNepNumber(404)}</h1>
          <p className="fs-3">
            <span className="text-danger">{languageStatus === 'en' ? `Sorry, Page not found`: `माफ गर्नुहोस्, पृष्ठ फेला परेन`}</span>
          </p>
          <p className="lead">{languageStatus === 'en' ? `The page you’re looking for doesn’t exist.` : `तपाईंले खोज्नु भएको पृष्ठ अवस्थित छैन।`}</p>
          <Link to="/" className="btn btn-primary">
            {languageStatus === 'en' ? `Return to Homepage` : `गृहपृष्ठ मा फर्कनुहोस्`}
          </Link>
        </div>
      </div>
    </main>
  );
}

export default NotFoundPage;
