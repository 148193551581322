import axios from "axios";
import {Buffer} from "buffer";
import {useSelector} from "react-redux";

function ApiService() {
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    const USERNAME = process.env.REACT_APP_USERNAME;
    const PASSWORD = process.env.REACT_APP_PASSWORD;

    const {organizationId} = useSelector((state) => state.organization);
    axios.defaults.baseURL = BASE_URL;
    axios.defaults.headers.post["Content-Type"] = "application/json";
    axios.defaults.headers.common["Access-Control-Allow-Origin"] = false;

    const token = `${USERNAME}:${PASSWORD}`;
    const encodedToken = Buffer.from(token).toString("base64");
    axios.interceptors.request.use(function (config) {
        config.headers.Authorization = `Basic ${encodedToken}`;
        return config;
    });

    const fetchOrgIdByDomainName = async (domain) => {
        const response = await axios.get(
            `WaterTariffSystem-web/webresources/customer/domainOrgId/${domain}`
        );
        return response;
    };

    // fetch News And Updates
    const fetchNewsUpdates = async () => {
        const response = await axios.get(
            `WaterTariffSystem-web/webresources/customer/newsAndNotice/web/${organizationId}/news`
        );

        return response;
    };

    const fetchNoticeUpdates = async () => {
        const response = await axios.get(
            `WaterTariffSystem-web/webresources/customer/newsAndNotice/web/${organizationId}/notice`
        );

        return response;
    };

    const fetchContact = async () => {
        const response = await axios.get(
            `WaterTariffSystem-web/webresources/customer/contactList/${organizationId}`
        );

        return response;
    };
    const fetchAboutUs = async () => {
        const response = await axios.get(
            `WaterTariffSystem-web/webresources/customer/aboutUs/${organizationId}`
        );
        return response;
    };
    const fetchSliderImages = async () => {
        const response = await axios.get(
            `WaterTariffSystem-web/webresources/customer/sliderImages/${organizationId}`
        );

        return response;
    };
    const fetchStaffs = async () => {
        const response = await axios.get(
            `WaterTariffSystem-web/webresources/customer/staffCommittee/${organizationId}`
        );

        return response;
    };
    const fetchGalleries = async () => {
        const response = await axios.get(
            `WaterTariffSystem-web/webresources/customer/organizationGallery/web/${organizationId}`
        );
        return response;
    };

    const fetchDownloads = async () => {
        const response = await axios.get(
            `WaterTariffSystem-web/webresources/customer/organizationDocument/${organizationId}`
        );
        return response;
    };

    const fetchMessages = async () => {
        const response = await axios.get(
            `WaterTariffSystem-web/webresources/customer/messagesFromStaffs/${organizationId}`
        );
        return response;
    };

    const fetchPopup = async () => {
        const response = await axios.get(
            `WaterTariffSystem-web/webresources/customer/popup/${organizationId}`
        );
        return response;
    };

    const fetchNewsDetailById = async (newsId) => {
        const response = await axios.get(
            `WaterTariffSystem-web/webresources/customer/newsAndUpdatesById/${organizationId}/${newsId}`
        );
        return response;
    };

    const fetchServices = async () => {
        const response = await axios.get(
            `WaterTariffSystem-web/webresources/customer/ourServices/web/${organizationId}`
        );
        return response;
    };

    const fetchServiceDetailById = async (serviceId) => {
        const response = await axios.get(
            `WaterTariffSystem-web/webresources/customer/ourServicesById/${organizationId}/${serviceId}`
        );
        return response;
    };

    const fetchOrganizationDetails = async () => {
        const response = await axios.get(
            `WaterTariffSystem-web/webresources/v2/sms/fetchOrganizationDetailsWeb/${organizationId}`
        );
        return response;
    };

    const fetchScheduleList = async () => {
        const response = await axios.get(
            `WaterTariffSystem-web/webresources/nrw/wdMasterList/${organizationId}`
        );
        return response;
    };

    const fetchScheduleById = async (id) => {
        const response = await axios.get(
            `WaterTariffSystem-web/webresources/nrw/v2/wdScheduleList/${organizationId}/${id}`
        );
        return response;
    };

    const fetchOutStanding = async (customerNo) => {
        const response = await axios.get(
            `WaterTariffSystem-web/webresources/customer/outstandingForWebSite/${organizationId}/${customerNo}`
        );
        return response;
    };

    const fetchReadingScheduleList = async () => {
        const response = await axios.get(
            `WaterTariffSystem-web/webresources/customer/meterReadingSchedule/${organizationId}`
        );
        return response;
    };

    const fetchPaymentGatewayList = async () => {
        const response = await axios.get(
            `WaterTariffSystem-web/webresources/admin/paymentGateWayURL/${organizationId}`
        );
        return response;
    };

    const fetchComplainTypes = async () => {
        const response = await axios.get(
            `WaterTariffSystem-web/webresources/customer/complainTypesForWebsite/${organizationId}`
        );
        return response;
    };

    const complainEntry = async (data) => {
        const response = await axios.post(
            `/WaterTariffSystem-web/webresources/customer/saveComplainEntry`,
            data
        );
        return response;
    };

    return {
        fetchNewsUpdates,
        fetchNoticeUpdates,
        fetchContact,
        fetchAboutUs,
        fetchSliderImages,
        fetchStaffs,
        fetchGalleries,
        fetchDownloads,
        fetchMessages,
        fetchPopup,
        fetchNewsDetailById,
        fetchServices,
        fetchOrganizationDetails,
        fetchScheduleList,
        fetchScheduleById,
        fetchOutStanding,
        fetchServiceDetailById,
        fetchReadingScheduleList,
        fetchPaymentGatewayList,
        fetchComplainTypes,
        complainEntry,
        fetchOrgIdByDomainName,
    };
}

export default ApiService;
