import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import ApiService from "../../../../network/ApiService";
import "./MeterSchedule.css";
import engToNepNumber from "../../../../helpers/EngToNepNumber";

function MeterScheduleList() {
    const {organizationId} = useSelector((state) => state.organization);
    const languageStatus = sessionStorage.getItem("language");
    const {
        fetchReadingScheduleList,
    } = ApiService();

    const [scheduleList, setScheduleList] = useState([]);
    const getSchedule = async () => {
        try {
            const response = await fetchReadingScheduleList();
            setScheduleList(response.data);
        } catch (error) {
            console.log(error.message);
        }
    };

    useEffect(() => {
        if (organizationId) {
            getSchedule();
        }
    }, [organizationId]);
    return (
        <section className="section-padding mt-3">
      <div className="container">
        <div className="col-lg-12 col-12 text-center mb-4">
          <h4>{languageStatus === 'en' ? `Meter Reading Schedule` : `रिडिङ्ग तालिका`} </h4>
        </div>
          <div className="row no-gutters">
          <div className="col-md-12 d-flex align-items-stretch">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>#</th>
                  <th>{languageStatus === 'en' ? `Area` : `क्षेत्र`} </th>
                  <th>{languageStatus === 'en' ? `Day of Month` : `महिना को दिन`}</th>
                  <th>{languageStatus === 'en' ? `Reading By` : `रिडिङ्ग गर्ने व्यक्ति`}</th>
                </tr>
              </thead>
              <tbody>
                {scheduleList.length > 0
                    ? scheduleList.map((data, index) => {
                        return (
                            <tr key={index}>
                          <th>{languageStatus === 'en' ? index + 1 : engToNepNumber(index + 1)}</th>
                          <td>{languageStatus === 'en' ? (data.zone ?? data.zoneNp) : (data.zoneNp ?? data.zone)}</td>
                          <td>{languageStatus === 'en' ? data.dayOfMonth : engToNepNumber(data.dayOfMonth)}</td>
                          <td>
                            {data.meterReaders.trim() !== ""
                                ? (languageStatus === 'en' ? (data.meterReadersEn ?? data.meterReaders) : data.meterReaders)
                                : (languageStatus === 'en' ? 'N/A' : `उपलब्ध छैन`)}
                          </td>
                        </tr>
                        );
                    })
                    :
                    <tr>
                      <td
                          colSpan={4}
                          className="uk-text-bold"
                          style={{fontSize: '20px'}}
                      >{languageStatus === 'en' ? 'Meter Schedule Not Found' : `रिडिङ्ग तालिका भेटियन`}...</td>
                    </tr>
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>
    );
}

export default MeterScheduleList;
