import React, {useEffect, useState} from "react";
import Lightbox from "yet-another-react-lightbox";
import "./Gallery.css";
import "yet-another-react-lightbox/styles.css";
import ApiService from "../../../network/ApiService";
import {useSelector} from "react-redux";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import {Link} from "react-router-dom";

function Gallery(props) {
    const {
        fetchGalleries,
    } = ApiService();
    const {organizationId} = useSelector((state) => state.organization);
    const languageStatus = sessionStorage.getItem("language");
    const [open, setOpen] = useState(false);
    const [slides, setSlides] = useState([]);

    const [galleryData, setGalleryData] = useState([]);

    const fetchGalleryImages = async () => {
        try {
            const response = await fetchGalleries();
            setGalleryData(response.data.messageWithImg);
        } catch (error) {
            console.log(error.message);
        }
    };

    const handleViewSlides = (galleryImages) => {
        const images = [];
        galleryImages.map((value, item) => {
            images.push({
                src: value,
                alt: "image",
                width: 3840,
                height: 2560,
            });
        });
        setSlides(images);
        setOpen(!open);
    };

    useEffect(() => {
        if (organizationId) {
            fetchGalleryImages();
        }
    }, [organizationId]);

    return (
      <section id="about" className="section-padding">
        <div className="container">
            <div className="row">
          <div className="section-title">
            <h2>{languageStatus === 'en' ? `Photo Gallery` : `फोटो ग्यालरी`}</h2>
          </div>
              {/*<div className="col-lg-12">*/}
              {/*    <Lightbox*/}
              {/*        open={open}*/}
              {/*        close={() => setOpen(!open)}*/}
              {/*        slides={slides}*/}
              {/*        plugins={[Thumbnails]}*/}
              {/*        carousel={{finite: true}}*/}
              {/*    />*/}
              {/*    {galleryData.length > 0*/}
              {/*        ? galleryData.map((data, index) => {*/}
              {/*            return (*/}
              {/*                <div*/}
              {/*                    className="card col-lg-4 col-md-6 col-12 mb-4 mb-lg-0"*/}
              {/*                    key={index}*/}
              {/*                    onClick={() => handleViewSlides(data.galleryUrls)}*/}
              {/*                >*/}
              {/*                  <img*/}
              {/*                      src={*/}
              {/*                          data.imageUrl && data.imageUrl !== "n/a"*/}
              {/*                              ? data.imageUrl*/}
              {/*                              : "assets/images/placeholder.jpg"*/}
              {/*                      }*/}
              {/*                  />*/}
              {/*                  <div className=" mt-2 img-label text-center">*/}
              {/*                    <span className="text-center">{languageStatus === 'en' ? (data.header ?? data.headerNp) : (data.headerNp ?? data.header)}</span>*/}
              {/*                  </div>*/}
              {/*                </div>*/}
              {/*            );*/}
              {/*        })*/}
              {/*        : <>*/}
              {/*          <div style={{border: '1px solid', borderRadius: '5px', padding: '5px'}}>*/}
              {/*            <div*/}
              {/*                className="col-md-12"*/}
              {/*                style={{fontSize: '24px'}}*/}
              {/*            >*/}
              {/*              {languageStatus === 'en' ? `Photo Gallery Not Found` : `फोटो ग्यालरी भेटियन`}...*/}
              {/*            </div>*/}
              {/*          </div>*/}
              {/*      </>}*/}
              {/*</div>*/}
                <Lightbox
                    open={open}
                    close={() => setOpen(!open)}
                    slides={slides}
                    plugins={[Thumbnails]}
                    carousel={{finite: true}}
                />
                {galleryData.length > 0
                    ? galleryData.map((data, index) => {
                        return (
                            <div className="col-lg-3 col-md-6 col-12 mb-4 news-featured-block gallery-image-block">
                                <div className="news-custom-block-wrap news-featured-block-image"
                                     onClick={() => handleViewSlides(data.galleryUrls)}
                                >
                                    <img
                                        style={{ cursor: 'pointer', maxHeight: `230px`}}
                                        src={
                                            data.imageUrl && data.imageUrl !== "n/a"
                                                ? data.imageUrl
                                                : "assets/images/placeholder.jpg"
                                        }
                                        className="news-featured-block-image img-fluid "
                                    />

                                    <div className="custom-block">
                                        <Link className="custom-btn btn pe-auto">{languageStatus === 'en' ? (data.header ?? data.headerNp) : (data.headerNp ?? data.header)}</Link>
                                    </div>
                                </div>
                            </div>
                        )
                    }) :
                    <>
                        <div style={{border: '1px solid', borderRadius: '5px', padding: '5px'}}>
                          <div
                              className="col-md-12"
                              style={{fontSize: '24px'}}
                          >
                            {languageStatus === 'en' ? `Photo Gallery Not Found` : `फोटो ग्यालरी भेटियन`}...
                          </div>
                        </div>
                    </>
                }
            </div>
        </div>
      </section>
    );
}

export default Gallery;
