import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {Link} from "react-router-dom";
import ApiService from "../../network/ApiService";
import NepaliFlag from './svgs/np.svg';
import EnglishFlag from './svgs/us.svg';

function Navbar() {
    const {
        fetchMessages,
    } = ApiService();
    const {organizationId} = useSelector((state) => state.organization);
    const {orgDetailData} = useSelector((state) => state.contact);
    const [messages, setMessages] = useState([]);
    const [language, setLanguage] = useState('en');
    const languageStatus = sessionStorage.getItem("language");
    const [sticky, setSticky] = useState(false);
    const [email, setEmail] = useState(null);
    const {contactListData} = useSelector(
        (state) => state.contact
    );

    useEffect(() => {
        if (contactListData && Object.keys(contactListData).length > 0) {
            setEmail(contactListData.find((item) => item.contactType === "Email"));
        }
    }, [contactListData]);

    const fetchStaffMessages = async () => {
        try {
            const response = await fetchMessages();
            setMessages(response.data.mapOfIdAndDisplayLabels);
        } catch (error) {
            console.log(error.message);
        }
    };

    const handleShowDropdown = (event) => {
        if (event.target.nextElementSibling !== null) {
            event.target.nextElementSibling.classList.add('show');
        }
    }
    const handleToggleDropdown = (event) => {
        if (event.target.nextElementSibling !== null) {
            event.target.nextElementSibling.classList.toggle('show');
        }
    }

    const handleHideDropdown = () => {
        document.querySelectorAll('.dropdown-menu').forEach(e => e.classList.remove('show'))
    }

    useEffect(() => {
        if (organizationId) {
            fetchStaffMessages();
        }
    }, [organizationId]);

    const changeLanguage = (language: 'en') => {
        const languageStatus = sessionStorage.getItem("language");
        if (languageStatus !== '') {
            setLanguage(language);
            sessionStorage.setItem("language", language);
        }
    }

    useEffect(() => {
        const handleScroll = () => {
            setSticky(window.scrollY > 200);
            // console.log(window.scrollY);
        };
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    })

    return (
        <>
            <header className="site-header">
                <div className="container">
                    <div className="row">

                        <div className="col-lg-8 col-12 d-flex flex-wrap">
                            <p className="d-flex me-4 mb-0 fw-bold" style={{ fontSize: '20px'}}>
                                {/*<i className="bi-geo-alt me-2"></i>*/}
                                {languageStatus === 'en' ? (orgDetailData?.nameEnglish ?? orgDetailData?.nameNepali) : (orgDetailData?.nameNepali ?? orgDetailData?.nameEnglish)}
                            </p>

                            {/*<p className="d-flex mb-0">*/}
                            {/*    <i className="bi-envelope me-2"></i>*/}

                            {/*    <a href={`mailto:${email?.contactAddress}`}>*/}
                            {/*        {email?.contactAddress}*/}
                            {/*    </a>*/}
                            {/*</p>*/}
                        </div>

                        <div className="col-lg-3 col-12 ms-auto d-lg-block d-none">
                            <ul className="social-icon">
                                <li className="social-icon-item">
                                    <a
                                        href={orgDetailData ? orgDetailData?.twitterUrl : ""}
                                        target="_blank"
                                        className="social-icon-link bi-twitter"
                                    ></a>
                                </li>

                                <li className="social-icon-item">
                                    <a
                                        href={orgDetailData ? orgDetailData?.facebookUrl : ""}
                                        target="_blank"
                                        className="social-icon-link bi-facebook"
                                    ></a>
                                </li>

                                <li className="social-icon-item">
                                    <a
                                        href={orgDetailData ? orgDetailData?.linkedInUrl : ""}
                                        target="_blank"
                                        className="social-icon-link bi-linkedin"
                                    ></a>
                                </li>

                                <li className="social-icon-item">
                                    <a
                                        href={orgDetailData ? orgDetailData?.youtubeUrl : ""}
                                        target="_blank"
                                        className="social-icon-link bi-youtube"
                                    ></a>
                                </li>

                            </ul>
                        </div>

                    </div>
                </div>
            </header>
            {/*<nav className="navbar navbar-expand-lg bg-light shadow-lg">*/}
            {/*    <div className="container">*/}
            {/*      <Link className="navbar-brand" to="/">*/}
            {/*          <img*/}
            {/*              src={*/}
            {/*                  orgDetailData &&*/}
            {/*                  orgDetailData.logoPath &&*/}
            {/*                  orgDetailData.logoPath !== ""*/}
            {/*                      ? orgDetailData.logoPath*/}
            {/*                      : null*/}
            {/*              }*/}
            {/*              alt=""*/}
            {/*              className="logo img-fluid"*/}
            {/*          />*/}
            {/*      </Link>*/}
            {/*      <button*/}
            {/*          className="navbar-toggler"*/}
            {/*          type="button"*/}
            {/*          data-toggle="collapse"*/}
            {/*          data-target="#ftco-nav"*/}
            {/*          aria-controls="ftco-nav"*/}
            {/*          aria-expanded="false"*/}
            {/*          aria-label="Toggle navigation"*/}
            {/*      >*/}
            {/*        <span className="oi oi-menu"></span> {languageStatus === 'en' ? 'Menu' : `मेनु`}*/}
            {/*      </button>*/}
            {/*      <div className="collapse navbar-collapse" id="ftco-nav">*/}
            {/*        <ul className="nav navbar-nav ml-auto">*/}
            {/*          <li className="nav-item" onMouseOver={handleShowDropdown}><Link*/}
            {/*              to="/"*/}
            {/*              className={languageStatus === 'en' ? 'nav-link nav-eng-nep' : `nav-link`}*/}
            {/*          >{languageStatus === 'en' ? 'Home' : `गृहपृष्ठ`}</Link></li>*/}
            {/*          <li*/}
            {/*              className="dropdown nav-item"*/}
            {/*              onClick={handleToggleDropdown}*/}
            {/*              onMouseOver={handleShowDropdown}*/}
            {/*              onMouseLeave={handleHideDropdown}*/}
            {/*          >*/}
            {/*              <a*/}
            {/*                  className={languageStatus === 'en' ? 'nav-link nav-eng-nep' : `nav-link`}*/}
            {/*                  href="#"*/}
            {/*              >{languageStatus === 'en' ? 'About Us' : `हाम्रो बारेमा`} <b className="fa fa-chevron-down"></b></a>*/}
            {/*              <ul className="dropdown-menu px-3">*/}
            {/*                <li className="nav-item pt-2"><Link to="/about">{languageStatus === 'en' ? 'Office Information' : `संस्थाको जानकारी`}</Link></li>*/}
            {/*                  {Object.keys(messages).length > 0*/}
            {/*                      ? Object.keys(messages).map((key) => {*/}
            {/*                          return (*/}
            {/*                              <li className="nav-item pt-2" key={key}>*/}
            {/*                                  <Link to={`/messages/${key}`}>*/}
            {/*                                      {languageStatus === 'en' ? messages[key].designation : messages[key].designationNP} {languageStatus === 'en' ? `'s Speech` : `को मन्तव्य`}*/}
            {/*                                  </Link>*/}
            {/*                              </li>*/}
            {/*                          );*/}
            {/*                      })*/}
            {/*                      : null}*/}
            {/*              </ul>*/}
            {/*          </li>*/}
            {/*            <li*/}
            {/*                className="dropdown nav-item"*/}
            {/*                onClick={handleToggleDropdown}*/}
            {/*                onMouseOver={handleShowDropdown}*/}
            {/*                onMouseLeave={handleHideDropdown}*/}
            {/*            >*/}
            {/*              <a*/}
            {/*                  className={languageStatus === 'en' ? 'nav-link nav-eng-nep' : `nav-link`}*/}
            {/*                  href="#"*/}
            {/*              >{languageStatus === 'en' ? 'News/Notices' : `समाचार/सुचना`} <b className="fa fa-chevron-down"></b></a>*/}
            {/*              <ul className="dropdown-menu px-3">*/}
            {/*                <li className="nav-item pt-2"><Link to="/news">समाचार</Link></li>*/}
            {/*                <li className="nav-item pt-2"><Link to="/notice">सुचना</Link></li>*/}
            {/*              </ul>*/}
            {/*          </li>*/}
            {/*            <li*/}
            {/*                className="dropdown nav-item"*/}
            {/*                onClick={handleToggleDropdown}*/}
            {/*                onMouseOver={handleShowDropdown}*/}
            {/*                onMouseLeave={handleHideDropdown}*/}
            {/*            >*/}
            {/*              <a*/}
            {/*                  className={languageStatus === 'en' ? 'nav-link nav-eng-nep' : `nav-link`}*/}
            {/*                  href="#"*/}
            {/*              >{languageStatus === 'en' ? 'E-Services' : `ई-सर्विसेस`} <b className="fa fa-chevron-down"></b></a>*/}
            {/*              <ul className="dropdown-menu px-3">*/}
            {/*                <li className="nav-item pt-2"><Link to="/our-services">{languageStatus === 'en' ? 'Our Services' : `हाम्रो सेवाहरु`}</Link></li>*/}
            {/*                <li className="nav-item pt-2"><Link to="/outstanding-report">{languageStatus === 'en' ? 'Outstanding Details' : `बक्यौता विवरण`}</Link></li>*/}
            {/*                <li className="nav-item pt-2"><Link to="/complain-service">{languageStatus === 'en' ? 'Complain Register' : `गुनासो सेवा`}</Link></li>*/}
            {/*                <li className="nav-item pt-2"><Link to="/water-schedule">{languageStatus === 'en' ? 'Water Distribution Schedule' : `पानी बितरण तालिका`}</Link></li>*/}
            {/*                <li className="nav-item pt-2"><Link to="/meter-reading-schedule">{languageStatus === 'en' ? 'Meter Reading Schedule' : `रिडिङ्ग तालिका`}</Link></li>*/}
            {/*              </ul>*/}
            {/*          </li>*/}
            {/*          <li className="nav-item"><Link to="/download" className={languageStatus === 'en' ? 'nav-link nav-eng-nep' : `nav-link`}>*/}
            {/*              {languageStatus === 'en' ? 'Download' : `डाउनलोड`}*/}
            {/*          </Link></li>*/}
            {/*          <li className="nav-item"><Link to="/gallery" className={languageStatus === 'en' ? 'nav-link nav-eng-nep' : `nav-link`}>*/}
            {/*              {languageStatus === 'en' ? 'Photo Gallery' : `फोटो ग्यालरी`}*/}
            {/*          </Link></li>*/}
            {/*          <li className="nav-item"><Link to="/team" className={languageStatus === 'en' ? 'nav-link nav-eng-nep' : `nav-link`}>*/}
            {/*              {languageStatus === 'en' ? 'Members' : `सदस्य`}*/}
            {/*          </Link></li>*/}
            {/*          <li className="nav-item"><Link to="/contact" className={languageStatus === 'en' ? 'nav-link nav-eng-nep' : `nav-link`}>*/}
            {/*              {languageStatus === 'en' ? 'Contact' : `सम्पर्क`}*/}
            {/*          </Link></li>*/}
            {/*        </ul>*/}
            {/*      </div>*/}
            {/*    </div>*/}
            {/*    <div className="language_buttons">*/}
            {/*      <Link*/}
            {/*          onClick={() => changeLanguage('en')}*/}
            {/*          className="mr-1"*/}
            {/*      >*/}
            {/*          <div*/}
            {/*              className=""*/}
            {/*              style={{*/}
            {/*                  cursor: 'pointer',*/}
            {/*                  outline: languageStatus === 'en' ? '2px solid white' : ''*/}
            {/*              }}*/}
            {/*          >*/}
            {/*              <img*/}
            {/*                  src={EnglishFlag}*/}
            {/*                  width="36"*/}
            {/*                  height="36"*/}
            {/*                  className="el-image uk-svg uk-preserve"*/}
            {/*                  alt=""*/}
            {/*                  uk-svg=""*/}
            {/*                  hidden=""*/}
            {/*              />*/}
            {/*          </div>*/}
            {/*      </Link>*/}
            {/*      <Link onClick={() => changeLanguage('np')} className="uk-link uk-link-reset uk-margin-small-left">*/}
            {/*          <div*/}
            {/*              className="uk-button-link uk-border-rounded uk-box-shadow-small"*/}
            {/*              style={{*/}
            {/*                  cursor: 'pointer',*/}
            {/*                  outline: languageStatus === 'np' ? '2px solid white' : ''*/}
            {/*              }}*/}
            {/*          >*/}
            {/*                  <img*/}
            {/*                      src={NepaliFlag}*/}
            {/*                      width="36"*/}
            {/*                      height="36"*/}
            {/*                      className="el-image uk-svg uk-preserve"*/}
            {/*                      alt=""*/}
            {/*                      uk-svg=""*/}
            {/*                      hidden=""*/}
            {/*                  />*/}
            {/*          </div>*/}
            {/*      </Link>*/}
            {/*    </div>*/}
            {/*  </nav>*/}
            <nav className={`navbar navbar-expand-lg bg-light shadow-lg ${sticky ? 'sticky' : ''}`}>
        <div className="container">
            <a className="navbar-brand" href="index.php">
                <Link className="navbar-brand" to="/">
                      <img
                          src={
                              orgDetailData &&
                              orgDetailData.logoPath &&
                              orgDetailData.logoPath !== ""
                                  ? orgDetailData.logoPath
                                  : null
                          }
                          alt=""
                          className="logo img-fluid"
                      />
                  </Link>
            </a>

            <button
                className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
                aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation"
            >
                <span className="navbar-toggler-icon"></span>
            </button>

            <div className="collapse navbar-collapse" id="navbarNav">
                <ul className="navbar-nav ms-auto">
                    <li className="nav-item">
                        <Link
                            className="nav-link click-scroll"
                            to="/"
                        >{languageStatus === 'en' ? 'Home' : `गृहपृष्ठ`}</Link>
                    </li>

                    <li className="nav-item dropdown">
                        <a
                            className="nav-link click-scroll dropdown-toggle" href="#"
                            id="navbarLightDropdownMenuLink" role="button" data-bs-toggle="dropdown"
                            aria-expanded="false"
                        >{languageStatus === 'en' ? 'About Us' : `हाम्रो बारेमा`}</a>

                        <ul className="dropdown-menu dropdown-menu-light" aria-labelledby="navbarLightDropdownMenuLink">
                            <li><Link
                                className="dropdown-item"
                                to="/about"
                            >{languageStatus === 'en' ? 'Office Information' : `संस्थाको जानकारी`}</Link></li>
                            {Object.keys(messages).length > 0
                                ? Object.keys(messages).map((key) => {
                                    return (
                                        <li className="dropdown-item" key={key}>
                                              <Link to={`/messages/${key}`}>
                                                  {languageStatus === 'en' ? messages[key].designation : messages[key].designationNP} {languageStatus === 'en' ? `'s Speech` : `को मन्तव्य`}
                                              </Link>
                                          </li>
                                    );
                                })
                                : null}
                        </ul>
                    </li>
                    <li className="nav-item dropdown">
                        <a
                            className="nav-link click-scroll dropdown-toggle" href="#"
                            id="navbarLightDropdownMenuLink" role="button" data-bs-toggle="dropdown"
                            aria-expanded="false"
                        >{languageStatus === 'en' ? 'News/Notices' : `समाचार/सुचना`}</a>

                        <ul className="dropdown-menu dropdown-menu-light" aria-labelledby="navbarLightDropdownMenuLink">
                            <li>
                                <Link className="dropdown-item" to="/news">
                                    {languageStatus === 'en' ? 'News' : `समाचार`}
                                </Link>
                            </li>
                            <li>
                                <Link className="dropdown-item" to="/notice">
                                    {languageStatus === 'en' ? 'Notices' : `सुचना`}
                                </Link>
                            </li>
                        </ul>
                    </li>
                    <li className="nav-item dropdown">
                        <a
                            className="nav-link click-scroll dropdown-toggle" href="#"
                            id="navbarLightDropdownMenuLink" role="button" data-bs-toggle="dropdown"
                            aria-expanded="false"
                        >{languageStatus === 'en' ? 'E-Services' : `ई-सर्विसेस`}</a>

                        <ul className="dropdown-menu dropdown-menu-light" aria-labelledby="navbarLightDropdownMenuLink">
                            <li>
                                <Link className="dropdown-item" to="/our-services">
                                    {languageStatus === 'en' ? 'Our Services' : `हाम्रो सेवाहरु`}
                                </Link>
                            </li>
                            <li>
                                <Link className="dropdown-item" to="/outstanding-report">
                                    {languageStatus === 'en' ? 'Outstanding Details' : `बक्यौता विवरण`}
                                </Link>
                            </li>
                            <li>
                                <Link className="dropdown-item" to="/complain-service">
                                    {languageStatus === 'en' ? 'Complain Register' : `गुनासो सेवा`}
                                </Link>
                            </li>
                            <li>
                                <Link className="dropdown-item" to="/water-schedule">
                                    {languageStatus === 'en' ? 'Water Distribution Schedule' : `पानी बितरण तालिका`}
                                </Link>
                            </li>
                            <li>
                                <Link className="dropdown-item" to="/meter-reading-schedule">
                                    {languageStatus === 'en' ? 'Meter Reading Schedule' : `रिडिङ्ग तालिका`}
                                </Link>
                            </li>
                        </ul>
                    </li>


                    <li className="nav-item">
                        <Link className="nav-link click-scroll" to="/download">
                            {languageStatus === 'en' ? 'Download' : `डाउनलोड`}
                        </Link>
                    </li>

                    <li className="nav-item">
                        <Link className="nav-link click-scroll" to="/gallery">
                            {languageStatus === 'en' ? 'Photo Gallery' : `फोटो ग्यालरी`}
                        </Link>
                    </li>

                    <li className="nav-item">
                        <Link className="nav-link click-scroll" to="/team">
                            {languageStatus === 'en' ? 'Members' : `सदस्य`}
                        </Link>
                    </li>



                    <li className="nav-item">
                        <Link className="nav-link click-scroll" to="/contact">
                            {languageStatus === 'en' ? 'Contact' : `सम्पर्क`}
                        </Link>
                    </li>
                </ul>
            </div>
        </div>
                <div className="language_buttons">
                  <Link
                      onClick={() => changeLanguage('en')}
                      className="mr-1"
                  >
                      <div
                          className=""
                          style={{
                              cursor: 'pointer',
                              outline: languageStatus === 'en' ? '2px solid black' : ''
                          }}
                      >
                          <img
                              src={EnglishFlag}
                              width="36"
                              height="36"
                              className="el-image uk-svg uk-preserve"
                              alt=""
                              uk-svg=""
                              hidden=""
                          />
                      </div>
                  </Link>
                  <Link onClick={() => changeLanguage('np')} className="uk-link uk-link-reset uk-margin-small-left">
                      <div
                          className="uk-button-link uk-border-rounded uk-box-shadow-small"
                          style={{
                              cursor: 'pointer',
                              outline: languageStatus === 'np' ? '2px solid black' : ''
                          }}
                      >
                              <img
                                  src={NepaliFlag}
                                  width="36"
                                  height="36"
                                  className="el-image uk-svg uk-preserve"
                                  alt=""
                                  uk-svg=""
                                  hidden=""
                              />
                      </div>
                  </Link>
                </div>
    </nav>
        </>
    );
}

export default Navbar;
