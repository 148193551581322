import React from "react";
import {useEffect} from "react";
import {useState} from "react";
import {useSelector} from "react-redux";
import "./Contact.css";
import engToNepNumber from "../../helpers/EngToNepNumber";

function Contact() {
    const {contactListData, orgDetailData} = useSelector(
        (state) => state.contact
    );

    const [phone, setPhone] = useState(null);
    const [email, setEmail] = useState(null);
    const [orgDetail, setOrgDetail] = useState(null);
    const languageStatus = sessionStorage.getItem("language");

    useEffect(() => {
        if (contactListData && Object.keys(contactListData).length > 0) {
            setEmail(contactListData.find((item) => item.contactType === "Email"));
        }

    }, [contactListData]);

    useEffect(() => {
        if (orgDetailData && Object.keys(orgDetailData).length > 0) {
            setOrgDetail(orgDetailData);
        }
    }, [orgDetailData]);
    return (
        <>
        <main id="main">
          <section className="contact-section section-padding" id="section_6">
            <div className="container aos-init aos-animate mt-4 mb-4" data-aos="fade-up">
              <div className="section-title mb-4">
                <h2>{languageStatus === 'en' ? 'Contact' : `सम्पर्क`}</h2>
              </div>
              <div className="row">
                {orgDetailData &&
                orgDetailData.mapLink !== "N/A" &&
                orgDetailData.mapLink.trim() !== "" ? (
                    <div className="col-lg-6">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="info-box">
                          <h3><i className="contactIcon fa fa-map"></i></h3>
                          <h3>{languageStatus === 'en' ? `Address` : `ठेगाना`}</h3>
                          <p>{orgDetail ? (languageStatus === 'en' ? (orgDetail.addressEnglish ?? orgDetail.addressNepali) : (orgDetail.addressNepali ?? orgDetail.addressEnglish)) : null}</p>
                        </div>
                      </div>
                      <div className="col-md-6">
                        {email ? (
                            <div className="info-box mt-4">
                            <h3><i className="contactIcon fa fa-envelope"></i></h3>
                            <h3>{languageStatus === 'en' ? `Email` : `इमेल`}</h3>
                            <p>{email.contactAddress}</p>
                          </div>
                        ) : null}
                      </div>
                      <div className="col-md-6">
                        {orgDetailData ? (
                            <div className="info-box mt-4">
                            <h3><i className="contactIcon fa fa-phone"></i></h3>
                            <h3>{languageStatus === 'en' ? `Contact No.` : `सम्पर्क नम्बर`}</h3>
                            <p>{languageStatus === 'en' ? orgDetailData.contactNumber : (orgDetailData.contactNumberNp ?? engToNepNumber(orgDetailData.contactNumber))}</p>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                ) : (
                    <div className="col-lg-12">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="info-box">
                          <h3><i className="contactIcon fa fa-map"></i></h3>
                          <h3>{languageStatus === 'en' ? `Address` : `ठेगाना`}</h3>
                          <p>{orgDetail ? (languageStatus === 'en' ? (orgDetail.addressEnglish ?? orgDetail.addressNepali) : (orgDetail.addressNepali ?? orgDetail.addressEnglish)) : null}</p>
                        </div>
                      </div>
                        {email ? (
                      <div className="col-md-6">
                            <div className="info-box mt-4">
                            <h3><i className="contactIcon fa fa-envelope"></i></h3>
                            <h3>{languageStatus === 'en' ? `Email` : `इमेल`}</h3>
                            <p>{email.contactAddress}</p>
                          </div>
                      </div>
                        ) : null}
                        {orgDetailData ? (
                      <div className="col-md-6">
                            <div className="info-box mt-4">
                            <h3><i className="contactIcon fa fa-phone"></i></h3>
                            <h3>{languageStatus === 'en' ? `Contact No.` : `सम्पर्क नम्बर`}</h3>
                            <p>{orgDetailData.contactNumberNp}</p>
                          </div>
                      </div>
                        ) : null}
                    </div>
                  </div>
                )}
                  {orgDetailData &&
                  orgDetailData.mapLink !== "N/A" &&
                  orgDetailData.mapLink.trim() !== "" ? (
                      <div className="col-lg-6">
                    <iframe
                        className="mb-4 mb-lg-0"
                        src={orgDetailData.mapLink}
                        style={{
                            border: 0,
                            width: "100%",
                            height: "100%",
                        }}
                        allowFullScreen=""
                        frameBorder="0"
                    ></iframe>
                  </div>
                  ) : null}
              </div>
            </div>
          </section>
        </main>
      </>
    );
}

export default Contact;
